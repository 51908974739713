<template>
  <div class="container">
    <b-button @click="openEditor" style="margin-top: 50px" variant="outline-primary">Edit Website</b-button>
  </div>
</template>

<script>

export default {
  methods: {
    openEditor() {
      this.$router.push('editor')
    }
  }
}
</script>
